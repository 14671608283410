@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");

body {
  background-color: #000;
  font-family: Arial, Helvetica, sans-serif;
}
